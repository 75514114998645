import { createRouter, createWebHistory } from "vue-router";
import AnasayfaView from "../views/AnasayfaView.vue";
import LoginView from "../views/LoginView.vue";
import MusteriLoginView from "../views/MusteriLoginView.vue";
import MusteriPanelView from "../views/MusteriPanelView.vue";

import ProfilView from "../views/ProfilView.vue";


  import KullanicilarView from "../views/KullanicilarView.vue";
	import KullanicilarDetayView from "../views/KullanicilarDetayView.vue";

    import RollerView from "../views/RollerView.vue";

    import MusterilerView from "../views/MusterilerView.vue";
	import MusterilerDetayView from "../views/MusterilerDetayView.vue";

    import IslerView from "../views/IslerView.vue";
    import BenimIslerView from "../views/BenimIslerView.vue";
	import IslerDetayView from "../views/IslerDetayView.vue";

    import MusterinotlarView from "../views/MusterinotlarView.vue";

    import IsturlerView from "../views/IsturlerView.vue";

  import YapilacaklarView from "../views/YapilacaklarView.vue";
	import YapilacaklarDetayView from "../views/YapilacaklarDetayView.vue";



    import BildirimlerView from "../views/BildirimlerView.vue";

  import FikirlerView from "../views/FikirlerView.vue";
	

    const routes = [
        {
            path: "/login",
            name: "Login",
            component: LoginView,
        },
        
        {
            path: "/musterilogin",
            name: "musterilogin",
            component: MusteriLoginView,
        },
        {
          path: "/musteripanel",
          name: "musteripanel",
          component: MusteriPanelView,
      },

        {
            path: "/",
            name: "anasayfailk",
            component: AnasayfaView,
        },
        {
            path: "/anasayfa",
            name: "anasayfa",
            component: AnasayfaView,
        },
        {
            path: "/profil",
            name: "profil",
            component: ProfilView,
        },
        {
            path: "/kullanicilar",
            name: "kullanicilar",
            component: KullanicilarView,
        },
        {
            path: '/kullanicilar/:id/:tab?',
            name: 'kullanicilardetay',
            component: KullanicilarDetayView,
            beforeEnter: (to, from, next) => {
              // tab parametresini kontrol et
              if (!to.params.tab) {
                // tab parametresi belirtilmemişse, varsayılan değeri ata
                to.params.tab = 'bilgiler';
              }
              // Yönlendirmeyi devam ettir
              next();
            }
          },
        {
            path: "/roller",
            name: "roller",
            component: RollerView,
        },
   
        {
            path: "/musteriler",
            name: "musteriler",
            component: MusterilerView,
        },
        {
            path: '/musteriler/:id/:tab?',
            name: 'musterilerdetay',
            component: MusterilerDetayView,
            beforeEnter: (to, from, next) => {
              // tab parametresini kontrol et
              if (!to.params.tab) {
                // tab parametresi belirtilmemişse, varsayılan değeri ata
                to.params.tab = 'bilgiler';
              }
              // Yönlendirmeyi devam ettir
              next();
            }
          },
        {
            path: "/isler",
            name: "isler",
            component: IslerView,
        },
        {
          path: "/islerim",
          name: "islerim",
          component: BenimIslerView,
      },
        {
            path: '/isler/:id/',
            name: 'islerdetay',
            component: IslerDetayView,
          },
        {
            path: "/musterinotlar",
            name: "musterinotlar",
            component: MusterinotlarView,
        },
       
        {
            path: "/isturler",
            name: "isturler",
            component: IsturlerView,
        },
        
        {
            path: "/yapilacaklar",
            name: "yapilacaklar",
            component: YapilacaklarView,
        },
        {
            path: '/yapilacaklar/:id/:tab?',
            name: 'yapilacaklardetay',
            component: YapilacaklarDetayView,
            beforeEnter: (to, from, next) => {
              // tab parametresini kontrol et
              if (!to.params.tab) {
                // tab parametresi belirtilmemişse, varsayılan değeri ata
                to.params.tab = 'bilgiler';
              }
              // Yönlendirmeyi devam ettir
              next();
            }
          },
       
       
        {
            path: "/bildirimler",
            name: "bildirimler",
            component: BildirimlerView,
        },
     
        {
            path: "/fikirler",
            name: "fikirler",
            component: FikirlerView,
        },
      
     
        
    ];


const router = createRouter({
    history: createWebHistory(),
    routes,
});


router.beforeEach((to, from, next) => {

    const isLoggedIn = localStorage.getItem('token') || localStorage.getItem('musteritoken') ;
    if (!isLoggedIn && to.name !== 'Login' && to.name !== 'musterilogin') {
        next({ name: 'Login' });
    } else {
        next();
    }
});

export default router;