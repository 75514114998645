<template>

<div class="col">
            <div class="row">
         
       
                <!-- isadim  liste -->
                
                <div class="card mb-2 sh-10 sh-md-8"  v-for="isadim in paginatedisadimlar" :key="isadim.id" >
                          <div class="card-body pt-0 pb-0 h-100">
                            <div class="row g-0 h-100 align-content-center">
                              <div class="col-12 col-md-4 d-flex align-items-center mb-2 mb-md-0">
                                <span class="body-link text-truncate"> {{ isadim.ad }}</span>
                              </div>
                            

                              <div class="col-12 col-md-4 d-flex align-items-center justify-content-end text-muted text-medium">
                                <span v-if="isadim.yapildi==1 " class="badge bg-outline-primary me-1">Yapıldı</span>
                                <span v-if="isadim.yapildi!=1 " class="badge bg-outline-warning me-1">Bekliyor</span>
                              </div>

                              <div class="col-12 col-md-4 d-flex justify-content-end">
                                <button v-if="yetki_kontrol('isadim_duzenle')" @click="duzenle(isadim)" class="btn btn-icon btn-icon-only btn-foreground-alternate">
                                  <unicon name="pen" fill="#7c7c7c" class="icon" ></unicon>
                                </button>
                                <button  v-if="yetki_kontrol('isadim_sil')"  @click="sil(isadim.id)" class="btn btn-icon btn-icon-only btn-foreground-alternate">
                                  <unicon name="trash" fill="#7c7c7c" class="icon" ></unicon>
                                </button>
                              </div>
                            </div>
                          </div>
                  </div>
            
                <!-- isadim  liste -->
          
            </div>
            <div class="row mt-5">
              <div class="col-12 text-center">
                <!-- Sayfalama -->
                <nav>
                  <ul class="pagination">
                    <li class="page-item" :class="{ active: page === isadimlar.seciliSayfa }" v-for="page in pages" :key="page">
                      <a class="page-link" @click="isadimlarSayfaDegistir(page)">{{
                        page
                      }}</a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>



 
  
</template>

<script>
import axiosCustom from "@/services/axiosCustom";
import { yetkiKontrol } from '@/services/yetkiKontrol';

export default {
  props: {

    isadimlar:{
      type: Array,
      required: true
    },
    duzenle: Function,
    formKaydetSonrasi: Function,
    tur:String,
  },
  computed: {
    filteredisadimlar() {
      // Arama filtresine göre kullanıcıları filtrele
      return this.isadimlar.tumu.filter(
        (isadim) =>

          isadim.ad.toLowerCase() 
          
          .includes(this.isadimlar.arananKelime.toLowerCase()) ||isadim.ad.toLowerCase()
          
          
          .includes(this.isadimlar.arananKelime.toLowerCase()) ||isadim.aciklama.toLowerCase()
          
          .includes(this.isadimlar.arananKelime.toLowerCase())


    
      );
    },
    paginatedisadimlar() {
      const start = (this.isadimlar.seciliSayfa - 1) * this.isadimlar.sayfadaKayit;
      const end = start + this.isadimlar.sayfadaKayit;
      return this.filteredisadimlar.slice(start, end);
    },
    isadimlar_toplamSayfa() {
      const totalFilteredUsers = this.filteredisadimlar.length;
      const maxPage = Math.max(Math.ceil(totalFilteredUsers / this.isadimlar.sayfadaKayit), 1);
      if (this.isadimlar.seciliSayfa > maxPage) {
        this.isadimlarSayfaDegistir(maxPage);
      }
      return maxPage;
    },
    pages() {
        const totalPageCount = this.isadimlar_toplamSayfa;
        const maxVisiblePages = 5;
        const currentPage = this.isadimlar.seciliSayfa;

        if (totalPageCount <= maxVisiblePages) {
            return Array.from({ length: totalPageCount }, (_, index) => index + 1);
        }

        const pagesBefore = Math.max(currentPage - Math.floor(maxVisiblePages / 2), 1);
        const pagesAfter = Math.min(pagesBefore + maxVisiblePages - 1, totalPageCount);

        return Array.from({ length: pagesAfter - pagesBefore + 1 }, (_, index) => pagesBefore + index);
    },
  
  },
  methods: {
    yetki_kontrol(yetki){
      return yetkiKontrol(yetki);
    },
    async sil(isadimId) {
      const result = await this.$swal({
        title: "Emin misiniz?",
        text: "Bu işlemi geri alamayacaksınız!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Evet, sil!",
        cancelButtonText: "İptal",
      });

      if (result.isConfirmed) {
        try {
          await axiosCustom.post("isadimlar/sil/" + isadimId);
          this.$toast.success("Kullanıcı Silindi");
          this.formKaydetSonrasi();
        } catch (error) {
          console.error("Silme hatası: ", error);
          this.$toast.error("Silme hatası");
        }
      }
    },
    isadimlarSayfaDegistir(page) {
      this.$emit('update:isadimlar',{
      ...this.isadimlar,
      seciliSayfa: page
    });
    },
    isadimlarSayfadaKayitDegistir(value) {
      this.$emit('update:isadimlar',{
      ...this.isadimlar,
      sayfadaKayit: value
    });
      this.isadimlarSayfaDegistir(1);
    },
  },
};
</script>
