<template>
  <div class="anasayfa">
    <AppMain>
      <div class="container">
        
      <div class="page-title-container">
  

          <div class="row">
            <div class="col-12 col-md-7">
              <span
                class="align-middle text-muted d-inline-block lh-1 pb-2 pt-2 text-small"
                >Anasayfa</span
              >
              <h1 class="mb-0 pb-0 display-4" id="title">
                Online ajansım Takip Uygulaması
              </h1>
            </div>
          </div>
        </div>
        <div class="row">
        
          <div class="col-xl-12">
            <HizliMenu></HizliMenu>

            <div class="row">
              <div class="col-xl-6 mb-5">
                <YapilacaklarCard></YapilacaklarCard>
              </div>
              <div class="col-xl-6 mb-5">
                <BugunBitecekIsler></BugunBitecekIsler>
              </div>
            </div>

            <div class="row">
              <KullanicilarDurum></KullanicilarDurum>
            </div>

          </div>
        </div>
      </div>
    </AppMain>
  </div>
</template>

<script>
import AppMain from "@/components/AppMain.vue";
import BugunBitecekIsler from "@/components/anasayfa/BugunBitecekIsler.vue";
import HizliMenu from "@/components/anasayfa/HizliMenu.vue";
import YapilacaklarCard from "@/components/anasayfa/YapilacaklarCard.vue";
import KullanicilarDurum from "@/components/anasayfa/KullanicilarDurum.vue";

import axiosCustom from "@/services/axiosCustom";

export default {
  name: "AnasayfaView",
  components: {
    AppMain,
    BugunBitecekIsler,
    HizliMenu,
    YapilacaklarCard,
    KullanicilarDurum,
  },
  mounted() {
    
    this.jwtkontrol();

    const isFirstLoad = localStorage.getItem('isFirstLoad');
    if (!isFirstLoad) {
    this.reloadHomePage();
    localStorage.setItem('isFirstLoad', true);
  }
},
methods: {
 

  jwtkontrol() {
        axiosCustom
            .get('anasayfa/jwtkontrol/')
            .then((response) => {
                if(response.data.status=='false'){
                  localStorage.removeItem('token');
                  this.router.push({ name: 'Login' });
                }
 
            })
            .catch((error) => {
                console.error("Veri çekme hatası: ", error);
            });
            
    },
    reloadHomePage() {
    // Anasayfa için yeniden yükleme
    window.location.reload();
  }
      


},
};
</script>
