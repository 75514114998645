import axios from 'axios';

const token = localStorage.getItem('token') || '';

const axiosCustom = axios.create({
  baseURL: 'http://panel.onlineajansim.com/api/app/',
  headers: {
    Authorization: `Bearer ${token}`,
    Accept: 'application/json',
    'Content-Type': 'multipart/form-data'
  },
});

export default axiosCustom;